import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import Toaster from '@meforma/vue-toaster';
import VueGoogleMaps from '@fawmi/vue-google-maps'
// import Socket from './composables/webSocket/chat_websocket_send';
import CanvasJSChart from '@canvasjs/vue-charts';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueApexCharts from "vue3-apexcharts";
import VueSweetalert2 from 'vue-sweetalert2';

// import { VueReCaptcha} from 'vue-recaptcha-v3'
const i18n = createI18n({
    locale:localStorage.getItem('lang') || 'en',
    messages: {
      en: {
        Search:'Search',
        PDF:'PDF',
        logout:'Sign out',
        Signup:'Sign up',
        password:'Password',
        Login:'Login',
        FullName:'Full Name',
        PhoneNumber:'Phone Number',
        Email:'Email',
        Dashboard:'Dashboard',
        Username :'User name',
        AccountType:'Account Type',
        Freelancer:'Freelancer',
        AuditFirm:'Audit Firm',
        Suppliers :'Suppliers ',
        Regulator:'Regulator',
        Organization:'Organization',
        projects:'projects',
        Certificate:'Certificate',
        Compliance:'Compliance',
        InProcess:'In Process',
        Pending:'Pending',
        All:'All',
        Date:'Date',
        Action:'Action'
      },
      ar: {
        Search:'بحث',
        PDF:'PDF احصل على ملف',
        logout:'تسجيل خروج',
        Signup:'إشتراك',
        password:'اسم المستخدم',
        Login:'دخول',
        FullName:'الإسم بالكامل',
        PhoneNumber:'رقم الهاتف ',
        Email:'إيميل',
        Dashboard:'الصفحة الرئيسية',
        Username :'اسم المستخدم',
        AccountType:'حساب',
        Freelancer:'الأشخاص المستقلين',
        AuditFirm:'شركات التدقيق',
        Suppliers :'الموردين ',
        Regulator:'جهة حكومية',
        Organization:'شركة',
        projects:'المشاريع',
        Certificate:'الشهادات',
        Compliance:'الضوابط ',
        InProcess:'تحت التنفيذ',
        Pending:'معلقة',
        All:'الكل',
        Date:'التاريخ',
        Action:"الاجراءات"

      }
    }
  })
const app = createApp(App)
app.use(VueGoogleMaps, {
    load: {
        // 'YOUR_API_KEY_COMES_HERE'
        // 'AIzaSyDNS7nXnbp8nqbAzU5h1nuqAK5kaWAsP90'
        key: 'AIzaSyAdxmehf5dM8SXOKluCcljMb6QfzoNjv1g',
        libraries: "places"
    },
})
// app.use(Socket())
app.use(router)
app.use(VueApexCharts);
app.use(createPinia()) // Create the root store
app.use(Toaster,{timeout: 3000})
app.use(i18n)
app.use(CanvasJSChart); 
app.component('VueDatePicker', VueDatePicker);
app.component("v-select", vSelect);
app.use(VueSweetalert2)
// app.use(VueReCaptcha, { siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' })
app.mount('#app')

