<template>
  <div class="col-md-3 aside-profile-details">
    <div class="card">
      <!--begin::Body-->
      <div class="company-details">
        <div
          :class="show ? 'show' : 'hide'"
          class="menu-lg-rounded menu-state-bg menu-title-gray-700 menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch flex-grow-1"
        >
          <SideBarItems></SideBarItems>
          <div
            class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
            data-kt-menu="true"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { toggleSideBar } from "@/composables/toggle";

import SideBarItems from "./SideBarItems.vue";

const { show } = toggleSideBar();
</script>

<style scoped>
.arabic #app .menu-title-gray-700 .menu-item .menu-link {
  display: block;
}

@media screen and (max-width: 765px) {
  .hide {
    transition: left 0.5s;
    left: -268px;
    position: absolute;
    background: white;
    box-shadow: 2px 2px rgba(0, 0, 0, 0.1);
    height: 100vh;
    overflow-y: auto;
    z-index: 10;
    width: 250px;
    top: -30px;
  }
  .show {
    display: block;
    position: absolute;
    z-index: 10;
    background: white;
    width: 250px;
    top: -30px;
    left: -18px;
    box-shadow: 2px 2px rgba(0, 0, 0, 0.1);
    height: 100vh;
    overflow-y: auto;
    transition: left 0.5s;
  }
}
</style>
