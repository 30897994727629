import Login from "@/views/admin/TheLogin.vue";
import MyProfile from "@/views/admin/ProfileLayout.vue";
import Chatbox from "@/views/admin/ChatBox.vue";
import HomePage from "@/views/HomePage.vue";
import Dashboard from "@/views/SideBarMenues/DashboardPage.vue";
import Header from "@/components/layouts/TheHeader.vue";
import TermCondition from "@/views/TermCondition.vue";


const routes = [
    {
        path: "/Login",
        name: "Login",
        component: Login,
    },
    {
        path: "/Header",
        name: "Header",
        component: Header,
    },

    {
        path: "/footer",
        name: "footer",
        component: TermCondition,
    },
    {
        path: "/my-profile",
        name: "MyProfile",
        component: MyProfile,
    },
    {
        path: "/chat/:id?",
        name: "chat_box",
        component: Chatbox,
    },

    {
        path: "/",
        redirect: "/dashboard",
    },
    {
        path: "/",
        name: HomePage,
        component: HomePage,
        children: [
            {
                path: "dashboard",
                component: Dashboard,
            },
            {
                path: "/admin_footer",
                name: "admin_footer",
                component: TermCondition,
            },
            {
                path: "/signups",
                component: () => import("../views/SideBarMenues/SignupsList.vue"),
            },
            {
                path: "/signups/signup-setting",
                component: () => import("../components/SignupList/SignupSetting.vue"),
            },
            {
                path: "/new-signup",
                component: () =>
                    import("../components/SignupList/newSignup/NewSignup.vue"),
            },
            {
                path: "/signups/signup-preview/:id",
                component: () => import("../components/SignupList/SignupsPreview.vue"),
            },
            {
                path: "/categories",
                component: () => import("../views/SideBarMenues/CategoriesPage.vue"),
            },
            {
                path: "/admin-user",
                component: () => import("../views/SideBarMenues/AdminUsers.vue"),
            },
            {
                path: "/admin-user/add-new-user",
                component: () => import("@/components/AddNewUser.vue"),
            },
            {
                path: "/edit_admin_user/:id",
                name: "EditNewUser",
                component: () => import("../components/AddNewUser.vue"),
            },
            {
                path: "/registrations",
                component: () => import("@/views/SideBarMenues/RegistrationsList.vue"),
            },
            {
                path: "/compliance",
                component: () => import("../views/SideBarMenues/ComplianceList.vue"),
            },
            {
                path: "/add-compliance_list/:data",
                component: () => import("../components/AddCompliance.vue"),
            },
            {
                path: "/add-compliance_list/:name?",
                component: () => import("../views/SideBarMenues/AddCompliance.vue"),
            },
            {
                path: "/add-compliance_list/:id",
                name: "EditcompliancePage",
                component: () => import("../components/AddCompliance.vue"),
            },
            {
                path: "/audit-Request/:id?",
                name: "audit-Request",
                component: () => import("../views/SideBarMenues/AuditRequest.vue"),
            },
            {
                path: "/audit-Request/audit-Details/:id",
                component: () => import("../components/AuditRequest/AuditsDetails.vue"),
            },
            {
                path: "/audit-Request/comp-criteria",
                component: () =>
                    import("../components/AuditRequest/ComplianceCriteria.vue"),
            },
            {
                path: "/projects/:id?",
                name: "projects",
                component: () => import("../views/SideBarMenues/ProjectsList.vue"),
            },
            {
                path: "/organizations",
                component: () => import("../views/SideBarMenues/OrganizationsList.vue"),
            },
            {
                path: "/suppliers",
                component: () => import("../views/SideBarMenues/SuppliersList.vue"),
            },
            {
                path: "/freelancer",
                component: () => import("../views/SideBarMenues/FreelancerList.vue"),
            },
            {
                path: "/audit-firms",
                component: () => import("../views/SideBarMenues/AuditFirms.vue"),
            },
            {
                path: "/regulators",
                component: () => import("../views/SideBarMenues/RegulatorsList.vue"),
            },
            {
                path: "/project-payment",
                name: "project-payment",
                component: () => import("../views/SideBarMenues/ProjectPayment.vue"),
            },
            {
                path: "/project-payment/:id",
                name: "CollectionListP",
                component: () => import("../components/ProjectPayment/CollectionList.vue"),
            },
            {
                path: "/audit-payment/:id",
                name: "CollectionListA",
                component: () => import("../components/AuditPayment/CollectionList.vue"),
            },
            {
                path: "/audit-payment",
                name: "audit-payment",
                component: () => import("../views/SideBarMenues/AuditPayment.vue"),
            },
            {
                path: "/evaluate-supplier",
                component: () => import("../views/SideBarMenues/EvaluateSupplier.vue"),
            },
            {
                path: "/evaluate-supplier/add-evaluate-supplier",
                component: () =>
                    import("../components/EvaluateSupplier/AddEvaluateSupplier.vue"),
            },
            {
                path: "/evaluate-supplier/edit-evaluate-supplier/:id",
                name: "EditEvaluateSupplier",
                component: () =>
                    import("../components/EvaluateSupplier/EditEvaluateSupplier.vue"),
            },
            {
                path: "/content-page",
                component: () => import("../views/SideBarMenues/LegalPage.vue"),
            },
            {
                path: "/content-page/add-content-page",
                component: () => import("@/components/LegalPages/addLegalPage.vue"),
            },
            {
                path: "/content-page/edit-content-page/:id",
                name: "EditLegalPage",
                component: () => import("@/components/LegalPages/addLegalPage.vue"),
            },
            {
                path: "/star-rating",
                component: () => import("../views/SideBarMenues/StarRating.vue"),
            },
            {
                path: "/star-rating/add-star-rating",
                component: () => import("../components/StarRating/AddStarRating.vue"),
            },
            {
                path: "/star-rating/edit-star-rating/:id",
                name: "EditStarRating",
                component: () => import("../components/StarRating/EditStarRating.vue"),
            },
            {
                path: "/disputes/:id?",
                name: "disputes",
                component: () => import("../views/SideBarMenues/DisputesList.vue"),
            },
            {
                path: "/disputes/dispute-detail/:id?",
                name: "DisputeDetail",
                component: () => import("../components/Disputes/DisputeDetail.vue"),
            },
            {
                path: "/help-text",
                component: () => import("../views/SideBarMenues/HelpText.vue"),
            },
            {
                path: "/help-text/add-help-text",
                component: () => import("../components/HelpText/AddHelpText.vue"),
            },
            {
                path: "/help-text/edit-help-text/:id",
                name: "EditHelpText",
                component: () => import("../components/HelpText/AddHelpText.vue"),
            },
            {
                path: "/template",
                component: () => import("../views/SideBarMenues/TemplateList.vue"),
            },
            {
                path: "/template/add-template",
                component: () => import("../components/AddTemplate.vue"),
            },
            {
                path: "/template/edit-template/:id",
                name: "EditTemplate",
                component: () => import("../components/AddTemplate.vue"),
            },
            {
                path: "/payment-methods",
                component: () => import("../views/SideBarMenues/PaymentMethods.vue"),
            },
            {
                path: "/add-payment-methods",
                component: () => import("@/components/AddPaymentMethods.vue"),
            },
            {
                path: "/add-payment-methods/:id",
                name: "EditPaymentMethods",
                component: () => import("@/components/AddPaymentMethods.vue"),
            },
            {
                path: "/GMV_graph",
                name: "GMV_graph",
                component: () => import("@/views/SideBarMenues/GMV_Graph.vue"),
            },
            {
                path: "/Revenue_Graph",
                name: "Revenue_Graph",
                component: () => import("@/views/SideBarMenues/Revenue_Graph.vue"),
            },
            // {
            //   path: '/fee-management',
            //   component: () => import('@/views/SideBarMenues/FeeManagement.vue')
            // },
            // {
            //   path: '/add_fee-management',
            //   component: () => import('../components/FeeManagement/AddFee.vue')
            // },
            {
                path: "/general",
                component: () => import("../components/General/GeneralEmailList.vue"),
            },
            {
                path: "/general/add-email-info/:id?",
                name: "EditEmailReciver",
                component: () => import("../components/General/AddEmailInfo.vue"),
            },
            {
                path: "/supplier_report",
                name: "SupplierReport",
                component: () =>
                    import("@/views/SideBarMenues/Reports/SupplierReport.vue"),
            },
            {
                path: "/audited_report",
                name: "AuditedReport",
                component: () =>
                    import("@/views/SideBarMenues/Reports/AuditedReport.vue"),
            },
            {
                path: "/inprocess_report",
                name: "InprocessReport",
                component: () =>
                    import("@/views/SideBarMenues/Reports/InProcessReport.vue"),
            },
            {
                path: "/not_audited_report",
                name: "NotAuditedReport",
                component: () =>
                    import("@/views/SideBarMenues/Reports/NotAuditedReport.vue"),
            },
            {
                path: "/re_audited_report",
                name: "ReAuditedReport",
                component: () =>
                    import("@/views/SideBarMenues/Reports/ReAuditedReport.vue"),
            },
            {
                path: "/sample",
                name: "sample",
                component: () => import("@/views/SideBarMenues/SamplePage"),
            },

            // Tenants
            {
                path: "/tenants",
                component: () => import("../views/tenants/TenantsList.vue"),
            },
            {
                path: "/tenants/create",
                component: () => import("../views/tenants/TenantForm.vue"),
            },
            {
                path: "/tenants/edit/:id",
                component: () => import("../views/tenants/TenantForm.vue"),
            },
        ],
    },
];

export default routes;
